/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';

import img1 from '../../../assets/images/features/feature13/img1.jpg';
import img2 from '../../../assets/images/features/feature13/img2.jpg';
import img3 from '../../../assets/images/features/feature13/img3.jpg';
import img4 from '../../../assets/images/features/feature13/img4.jpg';
import img5 from '../../../assets/images/features/feature30/img1.jpg';

const FeatureComponent = () => {
    return (
        <div>
           
            <div className="bg-light spacer feature20 up">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <span className="label label-success label-rounded">Services</span>
                            <h2 className="title">Fast, Reliable Windshield Repair to Keep You Safe on the Road</h2>
                            <h6 className="subtitle">With our fast and reliable windshield repair services, we ensure minimal disruption to your day while restoring your vehicle's safety. Trust our expert technicians to get you back on the road with a clear, damage-free windshield in no time</h6>
                        </Col>
                    </Row>
                    <Row className="wrap-feature-20">
                        <Col lg="6">
                            <Card>
                                <Row>
                                    <Col md="8">
                                        <CardBody className="d-flex no-block">
                                            <div className="m-r-20"><img src={img1} width="70" className="rounded" alt="img" /></div>
                                            <div>
                                                <h5 className="font-medium">Windshield Replacement</h5>
                                            </div>
                                        </CardBody>
                                    </Col>
                                    <Col md="4" className="text-center">
                                        <a href="#" className="text-white linking bg-success-gradiant">Lets Talk <i className="ti-arrow-right"></i></a>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Row>
                                    <Col md="8">
                                        <CardBody className="d-flex no-block">
                                            <div className="m-r-20"><img src={img2} width="70" className="rounded" alt="img" /></div>
                                            <div>
                                                <h5 className="font-medium">Windshield chip and crack repair.</h5>
                                            </div>
                                        </CardBody>
                                    </Col>
                                    <Col md="4" className="text-center">
                                        <a href="#" className="text-white linking bg-success-gradiant">Lets Talk <i className="ti-arrow-right"></i></a>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Row>
                                    <Col md="8">
                                        <CardBody className="d-flex no-block">
                                            <div className="m-r-20"><img src={img3} width="70" className="rounded" alt="img" /></div>
                                            <div>
                                                <h5 className="font-medium">Side windows and rear auto glass replacement.</h5>
                                            </div>
                                        </CardBody>
                                    </Col>
                                    <Col md="4" className="text-center">
                                        <a href="#" className="text-white linking bg-success-gradiant">Lets Talk <i className="ti-arrow-right"></i></a>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Row>
                                    <Col md="8">
                                        <CardBody className="d-flex no-block">
                                            <div className="m-r-20"><img src={img4} width="70" className="rounded" alt="img" /></div>
                                            <div>
                                                <h5 className="font-medium">Free mobile services.</h5>
                                            </div>
                                        </CardBody>
                                    </Col>
                                    <Col md="4" className="text-center">
                                        <a href="#" className="text-white linking bg-success-gradiant">Lets Talk <i className="ti-arrow-right"></i></a>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
           
        </div>
    );
}

export default FeatureComponent;
